export const models = {
  advertisers: {
    model: {
      advertiser: 'String',
      social: "String",
      lastYearCampaign: "String",
      offices: "String",
      email: "String",
      name: "String"
    },
    table: 'billalo-dmp-dev-advertiser',
    keys: { hash: "advertiser" }
  },
  campaigns: {
    model: {
      advertiser: 'String',
      id: 'String',
      orders: '[String]',
      fee: 'Float',
      print_amount: 'Float',
      status: 'String',
      name: 'String',
      discount: 'Float',
      order_date: 'String',
      n_municipality: 'Int',
      from: 'String',
      target: 'String',
      to: 'String',
      total_amount: 'Float'
    },
    table: 'billalo-dmp-dev-campaign',
    keys: { hash: 'advertiser', range: 'id' },
    references: {
      orders_reference: {multi: "range", model: 'orders', reference_keys: {hash: 'id', range: 'orders'}},
      advertiser_reference: {model: 'advertisers', reference_keys: {hash: 'advertiser'}}

    }    
  },
  campaign_events: {
    model: {
      campaignId: 'String',
      timestamp: 'String',
      id: 'String',
      type: 'String'
    },
    table: 'billalo-dmp-dev-campaign-event',
    keys: { hash: 'campaignId', range: 'timestamp' }
  },
  campaign_states: {
    model: {
      id: 'String',
      timestamp: 'String',
      context: 'JSON',
      state: 'String'
    },
    table: "billalo-dmp-dev-campaign-state",
    keys: { hash: "id", range: "timestamp" }
  },
  mediaowners: {
    model: {
      mediaowner: 'String',
      website: 'String',
      n_orders: 'Int',
      avatar: 'String',
      offices: 'JSON',
      name: 'String',
      rea: 'String',
      pec: 'String',
      p_iva: 'String',
      registered_office: 'String',
      fees: 'Int',
      bank_account: 'String',
      stripe_account: 'String',
      payment_registered_office: 'String',
      payment_mode: 'String',
      society_form: 'String',
      phone: 'String',
      subscription_date: 'String'
    },
    table: "billalo-dmp-dev-mediaowner",
    keys: { hash: "mediaowner" }
  },
  orders: {
    model: {
      campaign: 'String',
      id: 'String',
      printer: 'String',
      status: 'String',
      billboards: 'JSON',
      campaign_name: 'String',
      order_date: 'String',
      from: 'String',
      amount: 'Float',
      media_owner: 'String',
      advertiser: 'String',
      to: 'String'
    },
    table: "billalo-dmp-dev-order",
    keys: { hash: "campaign", range: 'id' },
    references: {
      campaign_reference: {model: 'campaigns', reference_keys: {hash: 'advertiser', range: 'campaign'}},
      media_owner_reference: {model: 'mediaowners', reference_keys: {hash: 'media_owner'}},
      advertiser_reference: {model: 'advertisers', reference_keys: {hash: 'advertiser'}}
    }
  },
  order_attachments: {
    model: {
      order: "String",
      id: "String",
      caption: 'String',
      media_owner: 'String',
      campaign: "String",
      upload_date: "String"
    },
    table: "billalo-dmp-dev-order",
    keys: { hash: "order", range: 'id' }
  },
  pricings: {
    model: {
      media_owner: "String",
      id: "String",
      customer: "String",
      rule_type: "String",
      from: "String",
      amount_1: "Float",
      condition_1: "String",
      percentage: "Float",
      to: "String"
    },
    table: "billalo-ssp-dev-pricing",
    keys: { hash: "media_owner", range: 'id' }
  },
  billboards: {
    model: {
      city_code: "String",
      id: "String",
      size: "String",
      billalo_code: "String",
      municipality: "String",
      orientation: "String",
      imageUrl: "String",
      n_authorization: "String",
      property_type: "String",
      last_maintenance: "String",
      n_sheet: "Int",
      billalo_index: "JSON",
      latitude: "Float",
      available: "Boolean",
      lighting: "String",
      address: "String",
      creation_date: "String",
      base: "Int",
      media_type: "String",
      authorization_expiration: "String",
      height_ground: "String",
      height: "Int",
      cod_cimasa: "String",
      longitude: "Float",
      media_owner: "String",
      price: "Float",
      taxes: "JSON"
    },
    table: "billalo-ssp-stage-billboard",
    keys: { hash: "city_code", range: 'id' },
    references: {
      media_owner_reference: {model: 'mediaowners', reference_keys: {hash: 'media_owner'}}
    }    
  },
  availabilities: {
    model: {
      billboard_id: "String",
      start_busy_date: "String",
      media_owner: "String",
      advertiser: "String",
      is_maintenance: "Boolean"
    },
    table: "billalo-ssp-stage-availability",
    keys: { hash: "billboard_id", range: 'start_busy_date' },
    references: {
      media_owner_reference: {model: 'mediaowners', reference_keys: {hash: 'media_owner'}},
      billboard_reference: {model: 'billboards', reference_keys: {hash: 'media_owner', range: 'billboard_id'}},
    }    
  }

}