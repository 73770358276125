// in App.js
import React, { cloneElement, useMemo } from 'react';
import { Component } from 'react';
import { Amplify } from "@aws-amplify/core";
import { Admin, Resource, ListGuesser, ShowGuesser, EditGuesser } from 'react-admin';
import graphqlDataProvider from './rx_graphql_provider';
import jsonServerProvider from 'ra-data-json-server';
import { buildAuthProvider, buildDataProvider } from "react-admin-amplify";
import awsExports from "./aws-exports";
import { List, Datagrid, TextField, DateField, BooleanField } from 'react-admin';
import { useListContext } from 'react-admin';
import { Toolbar, Typography } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight'
import { ReferenceArrayField, SingleFieldList, ArrayField, FunctionField, Menu, Layout, MenuItemLink, DashboardMenuItem, EmailField, NumberField, ChipField, SimpleFormIterator, ArrayInput, Create, Edit, SimpleForm, TextInput, NumberInput, Button, Show, SimpleShowLayout, RichTextField, ReferenceField, TopToolbar, CreateButton, ExportButton, FilterButton, } from 'react-admin';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import Iframe from 'react-iframe'
import BookIcon from '@material-ui/icons/Book';
import IconEvent from '@material-ui/icons/Event';
import { Route } from 'react-router-dom';
import { ShowButton } from 'react-admin';
import { models } from './models'
import { MenuItem } from '@material-ui/core';
import MyAppBar from './MyAppBar';

import { OrderList, OrderShow} from './views/orders';
import { CampaignList, CampaignShow, CampaignEdit } from './views/campaigns';
import { BillboardList, BillboardShow } from './views/billboards';
import { AdvertiserList, AdvertiserShow } from './views/advertiser';
import { PricingList } from './views/pricing';
import { AvailabilityList, AvailabilityShow } from './views/availability';
import { MediaOwnerList, MediaOwnerShow } from './views/mediaowner';

Amplify.configure(awsExports);

const Aside = () => (
  <div style={{ width: 200, margin: '1em' }}>
    <Typography variant="h6">Post details</Typography>
    <Typography variant="body2">
      Posts will only be published once an editor approves them
    </Typography>
  </div>
);

const dataProvider = graphqlDataProvider('https://ut3lhi5h2c.execute-api.eu-west-1.amazonaws.com/dev/', models);

const Dashboard = () => (
  <Card>
    <CardHeader title="Welcome to the Billalo Administration" />
    <CardContent>
      <Iframe url="https://cloudwatch.amazonaws.com/dashboard.html?dashboard=billboards&context=eyJSIjoidXMtZWFzdC0xIiwiRCI6ImN3LWRiLTA4NDMxMzgxMTM2NyIsIlUiOiJ1cy1lYXN0LTFfQnhwSTlTa2VUIiwiQyI6Im5ycG41ZmNmajRjb21kdmJrZHVqMG9qMWYiLCJJIjoidXMtZWFzdC0xOjRjZGRjYmEyLTljMDQtNDM5Yy1hZTRlLTQ4ZmM2ZTQ3N2ZiZCIsIk8iOiJhcm46YXdzOmlhbTo6MDg0MzEzODExMzY3OnJvbGUvc2VydmljZS1yb2xlL0NXREJTaGFyaW5nLVB1YmxpY1JlYWRPbmx5QWNjZXNzLVFWWTNOOTJXIiwiTSI6IlB1YmxpYyJ9"
        width="100%"
        height="600px"
        id="myId"
        className="myClassname"
        display="block"
        position="relative" />
    </CardContent>
  </Card>
)

export const CustomMenu = (props) => (
  <Menu {...props}>
    <DashboardMenuItem />
    <MenuItem style={{ marginLeft: 4 }}>DMP</MenuItem>
    <MenuItemLink to="/mediaowners" primaryText="Concessionari" leftIcon={<BookIcon />} />
    <MenuItemLink to="/advertisers" primaryText="Advertisers" leftIcon={<BookIcon />} />
    <MenuItemLink to="/campaigns" primaryText="Campagne" leftIcon={<BookIcon />} />
    <MenuItemLink to="/orders" primaryText="Ordini" leftIcon={<BookIcon />} />
    <MenuItem style={{ marginLeft: 4 }}>SSP</MenuItem>
    <MenuItemLink to="/billboards" primaryText="Billboards" leftIcon={<BookIcon />} />
    <MenuItemLink to="/pricings" primaryText="Prezzi" leftIcon={<BookIcon />} />
    <MenuItemLink to="/availabilities" primaryText="Disponibilità" leftIcon={<BookIcon />} />

  </Menu>
);

export const CustomLayout = (props) => <Layout {...props} menu={CustomMenu} appBar={MyAppBar} />;

const App = () => {

  return (
    <Admin
      title="Billalo Admin"
      layout={CustomLayout}
      authProvider={buildAuthProvider({ authGroups: [] })}
      dataProvider={dataProvider}
      dashboard={Dashboard}
    >
      <Resource name="advertisers" list={AdvertiserList} show={AdvertiserShow} edit={EditGuesser} />
      <Resource name="campaigns" list={CampaignList} show={CampaignShow} edit={CampaignEdit} />
      <Resource name="campaign_events" list={ListGuesser} edit={EditGuesser} />
      <Resource name="campaign_states" list={ListGuesser} edit={EditGuesser} />
      <Resource name="mediaowners" list={MediaOwnerList} show={MediaOwnerShow} edit={EditGuesser} />
      <Resource name="orders" list={OrderList} show={OrderShow} edit={EditGuesser} />
      <Resource name="order_attachments" list={ListGuesser} edit={EditGuesser} />
      <Resource name="pricings" list={PricingList} edit={EditGuesser} />
      <Resource name="billboards" list={BillboardList} show={BillboardShow} edit={EditGuesser} />
      <Resource name="availabilities" list={AvailabilityList} show={AvailabilityShow} edit={EditGuesser} />
    </Admin>
  )
}

export default App;