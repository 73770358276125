import { Amplify } from "@aws-amplify/core";
import { Filter } from "./Filter";
import { Pagination } from "./Pagination";
import { ApolloClient, InMemoryCache, gql, useQuery, HttpLink } from '@apollo/client';
import { Buffer } from "buffer"
import raw from "raw.macro";

//REFRESH 3
const pluralize = require('pluralize')
const velocity = require('velocityjs');
const vtl = raw("./vtl/create.vtl");
console.log('with vtl: ', vtl);


const makeQueries = (models, modelName, params) => {

  console.log('in makeQueries: ', models, modelName)
  try {
    const context = {
      model: models[modelName],
      modelName: modelName,
      params: params,
      utils: {
        capitalize: (value) => value.charAt(0).toUpperCase() + value.slice(1),
        plural: (value) => pluralize.plural(value),
        expand: (value) => value.join("\n"),
        singular: (value) => pluralize.singular(value)

      }
    }

    console.log('with vtl: ', vtl.toString());
    console.log('with model: ', models[modelName]);
    const result = velocity.render(vtl.toString(), context, {});
    console.log('with velocity result: ', result);
    return gql(result)
  } catch (error) {
    console.error('with error in makeQueries: ', error);
  }

}

export const createFactory = (client, models) => {

  return async (resource, params) => {

    console.log('in create with ', resource, params);

    const queries = makeQueries(models, resource, params);
    const entityName = resource.charAt(0).toUpperCase() + resource.slice(1);
    let queryName = `add${pluralize.singular(entityName)}`
    console.log('with queries: ', queries);

    let variables = params.data;
    console.log('with variables: ', variables);

    /***** FILTER ATTRIBUTES FROM KEYS *****/
    let data = {};
    for(let i = 0; i < Object.keys(variables).length; i++){
      const name = Object.keys(variables)[i];
      console.log('with name: ', name);
      if (models[resource].keys.range) {
        if (models[resource].keys.hash != name && models[resource].keys.range != name) data[name] = variables[name];
      } else {
        if (models[resource].keys.hash != name)  data[name] = variables[name];
      }
    }
    delete data['id'];
    delete data['__typename'];
    variables.data = data;
    console.log('with variables2: ', variables);

    return new Promise(async (resolve, reject) => {

      const result = await client.mutate({ mutation: queries, variables: variables });
      console.log('with result: ', result);
      const rawData = result.data[queryName];

      const idStruct = { hash: rawData[models[resource].keys.hash] }
      if (models[resource].keys.range) {
        const index: string = models[resource].keys.range; idStruct["range"] = rawData[models[resource].keys.range]
      }

      const id = Buffer.from(JSON.stringify(idStruct)).toString("base64")
      console.log('with rawData: ', rawData)

      resolve({
        data: { id, ...rawData }
      })
    }) as any;
  }
}