/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_KH1T5VO9S",
    "aws_user_pools_web_client_id": "1kbg3f3417dmn179f5uqrdts1a",
};

export default awsmobile;
