import React, { cloneElement, useMemo } from 'react';
import { List, Datagrid, TextField, DateField, BooleanField } from 'react-admin';

import { ReferenceArrayField, SingleFieldList, ArrayField, FunctionField, Menu, Layout, MenuItemLink, DashboardMenuItem, EmailField, NumberField, ChipField, SimpleFormIterator, ArrayInput, Create, Edit, SimpleForm, TextInput, NumberInput, Button, Show, SimpleShowLayout, RichTextField, ReferenceField, TopToolbar, CreateButton, ExportButton, FilterButton, } from 'react-admin';
import { ShowButton } from 'react-admin';

const ListActions = (props) => (
  <TopToolbar>
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const OrderList = (props) => (
  <List {...props} actions={<ListActions />} bulkActionButtons={false}>
    <Datagrid>
      <ReferenceField label="Advertiser" source="advertiser_reference" reference="advertisers" link="show">
        <TextField source="advertiser" />
      </ReferenceField>
      <ReferenceField label="Media Owner" source="media_owner_reference" reference="mediaowners" link="show">
        <TextField source="mediaowner" />
      </ReferenceField>
      <ReferenceField label="Campaign" source="campaign_reference" reference="campaigns" link="show">
        <TextField source="name" />
      </ReferenceField>
      <FunctionField label="billboards n." render={record => `${record?.billboards?.length ?? ""}`} />
      <NumberField source="amount" options={{ style: 'currency', currency: 'EUR' }} />
      <DateField source="order_date" label="data ordine" />
      <DateField source="from" label="data di partenza" />
      <DateField source="to" label="data di fine" />
      <ChipField source="status" />
      <ShowButton basePath="/orders" label="Show" record={props.record} />
    </Datagrid>
  </List>
);

export const OrderShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="advertiser" fullWidth={true} />
      <TextField source="mediaowner" fullWidth={true} />
      <ReferenceField label="Campaign" source="campaign_reference" reference="campaigns" link="show">
        <TextField source="name" />
      </ReferenceField>
      <ArrayField source="billboards">
        <Datagrid>
          <TextField source="city_code" />
          <TextField source="name" />
          <NumberField source="latitude" />
          <NumberField source="longitude" />
          <NumberField source="price" options={{ style: 'currency', currency: 'EUR' }} />
          <DateField source="taxes.last_payment" label="Ultimo pagamento tasse" />
          <BooleanField source="available" />
          <ShowButton basePath="/billboards" label="Show" record={props.record} />
        </Datagrid>
      </ArrayField>
      <TextField source="print_amount" />
      <FunctionField label="billboards n." render={record => `${record?.billboards?.length ?? ""}`} />
      <NumberField source="amount" options={{ style: 'currency', currency: 'EUR' }} />
      <DateField source="order_date" label="data ordine" />
      <DateField source="from" label="data di partenza" />
      <DateField source="to" label="data di fine" />
      <ChipField source="status" />

    </SimpleShowLayout>
  </Show>
);