import React, { cloneElement, useMemo } from 'react';
import { List, Datagrid, TextField, DateField, BooleanField } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { ImageField, ReferenceArrayField, SingleFieldList, ArrayField, FunctionField, Menu, Layout, MenuItemLink, DashboardMenuItem, EmailField, NumberField, ChipField, SimpleFormIterator, ArrayInput, Create, Edit, SimpleForm, TextInput, NumberInput, Button, Show, SimpleShowLayout, RichTextField, ReferenceField, TopToolbar, CreateButton, ExportButton, FilterButton, } from 'react-admin';
import { ShowButton } from 'react-admin';
import {
  BoxedShowLayout,
  RaBox
} from 'ra-compact-ui';

const ListActions = (props) => (
  <TopToolbar>
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const BillboardList = (props) => (
  <List {...props} actions={<ListActions />} bulkActionButtons={false}>
    <Datagrid>
      <TextField source="city_code" />
      <TextField source="name" />
      <ReferenceField label="Media Owner" source="media_owner_reference" reference="mediaowners" link="show">
        <TextField source="mediaowner" />
      </ReferenceField>
      <NumberField source="latitude" />
      <NumberField source="longitude" />
      <NumberField source="price" options={{ style: 'currency', currency: 'EUR' }} />
      {/* <DateField source="taxes.last_payment" label="Ultimo pagamento tasse" /> */}
      {/* <ImageField source="imageUrl" title="title" /> */}
      <BooleanField source="available" />
      <ShowButton basePath="/billboards" label="Show" record={props.record} />
    </Datagrid>
  </List>
);

export const BillboardShowOld = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="city_code" fullWidth={true} />
      <ReferenceField label="Media Owner" source="media_owner_reference" reference="mediaowners" link="show">
        <TextField source="mediaowner" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

const useStyles = makeStyles(theme => ({
  detailsBox: {
    paddingRight: "50px",
    borderRight: "solid thin",
    marginRight: "50px",
  },
}));

export const BillboardShow = (props) => {

  const classes = useStyles();

  return (
    <Show {...props}>
      <BoxedShowLayout>
        <RaBox display="flex" >
          <RaBox display="flex" flexWrap="wrap" flexGrow={4} className={classes.detailsBox}>
            <RaBox flex="0 0 100%" display="flex" justifyContent="space-between">
              <ReferenceField label="Media Owner" source="media_owner_reference" reference="mediaowners" link="show">
                <TextField source="mediaowner" />
              </ReferenceField>
              <TextField source="city_code" fullWidth={true} />
              <TextField source="municipality" fullWidth={true} />
            </RaBox>
            <RaBox flex="0 0 100%" display="flex" justifyContent="space-between">
              <TextField source="cod_cimasa" fullWidth={true} />
              <TextField source="latitude" />
              <TextField source="longitude" />
            </RaBox>
            <RaBox flex="0 0 100%" display="flex" justifyContent="space-between">
              <TextField source="address" />
              <TextField source="price" />
              <TextField source="taxes" />
            </RaBox>
          </RaBox>
          <RaBox display="inline-flex" flexDirection="column" flexGrow={4}>
            <RaBox>
              <ImageField source="imageUrl" title="title" />
              <TextField source="billalo_code" fullWidth={true} />
              <TextField source="size" fullWidth={true} />
              <TextField source="orientation" fullWidth={true} />
              <TextField source="property_type" fullWidth={true} />
              <TextField source="n_sheet" fullWidth={true} />
              <TextField source="lighting" fullWidth={true} />
              <TextField source="base" fullWidth={true} />
              <TextField source="height" fullWidth={true} />
              <TextField source="height_grouund" fullWidth={true} />
            </RaBox>
          </RaBox>
        </RaBox>
      </BoxedShowLayout>
    </Show>
  )
};