import { Amplify } from "@aws-amplify/core";
import { Filter } from "./Filter";
import { Pagination } from "./Pagination";
import { ApolloClient, InMemoryCache, gql, useQuery, HttpLink } from '@apollo/client';
import raw from "raw.macro";
import { Buffer } from "buffer"
import { isDeepStrictEqual } from "util";

var pluralize = require('pluralize')

const velocity = require('velocityjs');
const vtl = raw("./vtl/getList.vtl");
console.log('with vtl: ', vtl);

//REFRESH 9
const makeQueries = (models, modelName) => {

  console.log('in makeQueries: ', models, modelName)
  try {
    const context = {
      models: models,
      modelName: modelName,
      utils: {
        capitalize: (value) => value.charAt(0).toUpperCase() + value.slice(1),
        plural: (value) => pluralize.plural(value),
        expand: (value) => value.join("\n"),
        singular: (value) => pluralize.singular(value)

      }
    }

    console.log('with vtl: ', vtl.toString());
    console.log('with model: ', models[modelName]);
    const result = velocity.render(vtl.toString(), context, {});
    console.log('with velocity result: ', result);
    return gql(result)
  } catch (error) {
    console.error('with error in makeQueries: ', error);
  }

}

export const getListFactory = (client, models) => {

  return async (resource, params) => {

    const entityName = pluralize.plural(resource.charAt(0).toUpperCase() + resource.slice(1));
    const queries = makeQueries(models, resource);

    console.log('in getList with queries')
    const userData = await Amplify.Auth.currentSession();
    // const queries = makeQueries(models);
    console.log('with queries: ', queries);

    let accessToken = userData.getAccessToken()
    let jwt = accessToken.getJwtToken()
    //You can print them to see the full objects
    console.log(`myAccessToken: ${JSON.stringify(accessToken)}`)
    console.log(`myJwt: ${jwt}`)

    console.log('in get list with ', resource, params);

    console.log('entityName: ', entityName);
    const { page, perPage } = params.pagination;
    const { filter } = params;

    let queryName = `list${entityName}`
    let queryVariables = Filter.getQueryVariables(filter);

    const querySignature = JSON.stringify({
      queryName,
      queryVariables,
      perPage,
    });
    console.log('with querySignature: ', querySignature)
    const nextToken = Pagination.getNextToken(querySignature, page);

    // Checks if page requested is out of range
    if (typeof nextToken === "undefined") {
      return {
        data: [],
        total: 0,
      }; // React admin will redirect to page 1
    }

    return new Promise(async (resolve, reject) => {

      try {
        console.log('try to queryName: ', queryName);
        console.log('with queries: ', queries);
        console.log('with specific query: ', queries[queryName]);
        const result = await client.query({ query: queries, variables: { limit: perPage, nextToken: nextToken, model: resource } });
        console.log('with result: ', result);

        const rawData = result.data[queryName].Items;
        const data = rawData.map((item) => {
          const { customer, ...others } = item;

          // const _id = item.id;
          // if (item.id ) {
          //   item._id = item.id;
          //   delete item.id
          // }
          const idStruct = { hash: item[models[resource].keys.hash] }
          if (models[resource].keys.range) {
            const index: string = models[resource].keys.range;
            idStruct["range"] = item[models[resource].keys.range]
          }

          const _id = Buffer.from(JSON.stringify(idStruct)).toString("base64")
          console.log('with id: ', _id);

          //If model has reference
          let references = {}
          console.log('before REFERENCE')
          if (models[resource].references && Object.keys(models[resource].references).length > 0) {
            console.log('REFERENCE: in')
            for (let i = 0; i < Object.keys(models[resource].references).length; i++) {
              const reference_name = Object.keys(models[resource].references)[i];
              const reference_model = models[resource].references[reference_name].model;
              console.log('reference_name: ', reference_name)
              console.log('reference_model: ', reference_model)

              if (models[resource].references[reference_name].multi != undefined) {
                console.log('in multi for field : ', models[resource].references[reference_name].multi)
                const multi_key = models[resource].references[reference_name].multi;
                const multi_field = models[resource].references[reference_name].reference_keys[multi_key];
                const results = []
                for (let i = 0; i < item[multi_field].length; i++) {
                  console.log('with data: ', item[multi_field][i])
                  const referenceStruct = { hash: multi_key == "hash" ? item[multi_field][i] : item[models[resource].references[reference_name].reference_keys.hash] }
                  if (models[reference_model].keys.range) {
                    referenceStruct["range"] = multi_key == "range" ? item[multi_field][i] : item[models[resource].references[reference_name].reference_keys.range]
                  }
                  results.push(Buffer.from(JSON.stringify(referenceStruct)).toString("base64"))
                }
                references[reference_name] = results;
              } else {

                const referenceStruct = { hash: item[models[resource].references[reference_name].reference_keys.hash] }
                if (models[reference_model].keys.range) {
                  referenceStruct["range"] = item[models[resource].references[reference_name].reference_keys.range]
                }
                console.log('referenceStruct: ', referenceStruct)

                references[reference_name] = Buffer.from(JSON.stringify(referenceStruct)).toString("base64")

              }
            }
          }


          if (item.id) {
            console.log('with id: old', item.id)
            const { id, ...new_item } = item;
            console.log('with id and new item: ', id, new_item);
            return { id: _id, ...new_item, ...references }
          } else {
            return { id: _id, ...item, ...references }
          }

        })

        Pagination.saveNextToken(result.data[queryName].LastEvaluatedKey, querySignature, page);

        // Computes total
        let total = (page - 1) * perPage + result.data[queryName].Items.length;
        if (result.data[queryName].LastEvaluatedKey) {
          total++; // Tells react admin that there is at least one more page
        }

        console.log('with final data: ', data);
        resolve({
          data: data,
          total: total
        } as any)

      } catch (error) {
        console.error('with error: ', error);
      }
    }) as any;

  }

}
