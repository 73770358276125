import React, { cloneElement, useMemo } from 'react';
import { List, Datagrid, TextField, DateField, BooleanField } from 'react-admin';

import { ReferenceArrayField, SingleFieldList, ArrayField, FunctionField, Menu, Layout, MenuItemLink, DashboardMenuItem, EmailField, NumberField, ChipField, SimpleFormIterator, ArrayInput, Create, Edit, SimpleForm, TextInput, NumberInput, Button, Show, SimpleShowLayout, RichTextField, ReferenceField, TopToolbar, CreateButton, ExportButton, FilterButton, } from 'react-admin';
import { ShowButton } from 'react-admin';

const ListActions = (props) => (
  <TopToolbar>
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const AvailabilityList = (props) => (
  <List {...props} actions={<ListActions />} bulkActionButtons={false}>
    <Datagrid>
      <ReferenceField label="Media Owner" source="media_owner_reference" reference="mediaowners" link="show">
        <TextField source="mediaowner" />
      </ReferenceField>
      <TextField source="billboard_id" />
      <TextField source="advertiser" />
      <DateField source="start_busy_date" label="data di inizio" />
      <BooleanField source="is_maintenance" />
      <ShowButton basePath="/availabilities" label="Show" record={props.record} />
    </Datagrid>
  </List>
);

export const AvailabilityShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="billboard_id" fullWidth={true} />
      <TextField source="start_busy_date" fullWidth={true} />
      <ReferenceField label="Media Owner" source="media_owner_reference" reference="mediaowners" link="show">
        <TextField source="mediaowner" />
      </ReferenceField>
      <TextField source="advertiser" fullWidth={true} />
      <BooleanField source="is_maintenance" fullWidth={true} />
    </SimpleShowLayout>
  </Show>
);