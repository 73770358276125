import React, { cloneElement, useMemo } from 'react';
import { List, Datagrid, TextField, DateField, BooleanField } from 'react-admin';

import { ReferenceArrayField, SingleFieldList, ArrayField, FunctionField, Menu, Layout, MenuItemLink, DashboardMenuItem, EmailField, NumberField, ChipField, SimpleFormIterator, ArrayInput, Create, Edit, SimpleForm, TextInput, NumberInput, Button, Show, SimpleShowLayout, RichTextField, ReferenceField, TopToolbar, CreateButton, ExportButton, FilterButton, } from 'react-admin';
import { ShowButton } from 'react-admin';

const ListActions = (props) => (
  <TopToolbar>
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const CampaignList = (props) => (
  <List {...props} actions={<ListActions />} bulkActionButtons={false}>
    <Datagrid>
      <ReferenceField label="Advertiser" source="advertiser_reference" reference="advertisers" link="show">
        <TextField source="advertiser" />
      </ReferenceField>
      <TextField source="name" />
      <DateField source="order_date" showTime={true} />
      <FunctionField label="order n." render={record => `${record?.orders?.length ?? ""}`} />
      <NumberField source="fee" options={{ style: 'currency', currency: 'EUR' }} />
      <ChipField source="status" />
      <NumberField source="total_amount" options={{ style: 'currency', currency: 'EUR' }} />
      <DateField source="from" />
      <DateField source="to" />
      <ShowButton basePath="/campaigns" label="Show" record={props.record} />
    </Datagrid>
  </List>
);

export const CampaignShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" fullWidth={true} />
      <TextField source="name" fullWidth={true} />
      <TextField source="advertiser" fullWidth={true} />
      {/* <ArrayField source="orders_reference">
        <Datagrid>
          <FunctionField label="orders." render={record => <div>{record}</div>} />
        </Datagrid>
      </ArrayField> */}
      <ReferenceArrayField label="Ordini" reference="orders" source="orders_reference">
        <Datagrid>
          <TextField source="original_id" label="OrderId" />
          <ReferenceField label="Media Owner" source="media_owner_reference" reference="mediaowners" link="show">
            <TextField source="mediaowner" />
          </ReferenceField>
          <FunctionField label="billboards n." render={record => `${record?.billboards?.length ?? ""}`} />
          <NumberField source="amount" options={{ style: 'currency', currency: 'EUR' }} />
          <ChipField source="status" />
          <ShowButton basePath="/orders" label="Show" record={props.record} />
        </Datagrid>
      </ReferenceArrayField>
      {/* <TextField source="orders" /> */}
      <NumberField source="fee" options={{ style: 'currency', currency: 'EUR' }} />
      <NumberField source="discount" />
      <NumberField source="print_amount" options={{ style: 'currency', currency: 'EUR' }} />
      <NumberField source="total_amount" options={{ style: 'currency', currency: 'EUR' }} />
      <DateField source="order_date" showTime={true} />
      <DateField source="from" />
      <DateField source="to" />
      <NumberField source="n_municipality" />

      <ChipField source="status" />
    </SimpleShowLayout>
  </Show>
);

export const CampaignEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" fullWidth={true} />
      <TextInput source="advertiser" />
      <NumberInput source="orders" />
      <ArrayInput source="orders">
        <SimpleFormIterator>
          <TextInput source="" fullWidth={true} />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput source="fee" />
      <TextInput source="print_amount" />
    </SimpleForm>
  </Edit>
);