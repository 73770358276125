import React, { cloneElement, useMemo } from 'react';
import { List, Datagrid, TextField, DateField, BooleanField } from 'react-admin';

import { ReferenceArrayField, SingleFieldList, ArrayField, FunctionField, Menu, Layout, MenuItemLink, DashboardMenuItem, EmailField, NumberField, ChipField, SimpleFormIterator, ArrayInput, Create, Edit, SimpleForm, TextInput, NumberInput, Button, Show, SimpleShowLayout, RichTextField, ReferenceField, TopToolbar, CreateButton, ExportButton, FilterButton, } from 'react-admin';
import { ShowButton } from 'react-admin';

const ListActions = (props) => (
  <TopToolbar>
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const AdvertiserList = (props) => (
  <List {...props} actions={<ListActions />} bulkActionButtons={false}>
    <Datagrid>
      <TextField source="advertiser" />
      <TextField source="name" />
      <TextField source="social" />
      <EmailField source="email" />
      <ShowButton basePath="/advertisers" label="Show" record={props.record} />
    </Datagrid>
  </List>
);

export const AdvertiserShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="advertiser" />
      <TextField source="name" />
      <TextField source="social" />
      <EmailField source="email" />
    </SimpleShowLayout>
  </Show>
);