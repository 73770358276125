import React, { cloneElement, useMemo } from 'react';
import { List, Datagrid, TextField, DateField, BooleanField, UrlField } from 'react-admin';

import { ReferenceArrayField, SingleFieldList, ArrayField, FunctionField, Menu, Layout, MenuItemLink, DashboardMenuItem, EmailField, NumberField, ChipField, SimpleFormIterator, ArrayInput, Create, Edit, SimpleForm, TextInput, NumberInput, Button, Show, SimpleShowLayout, RichTextField, ReferenceField, TopToolbar, CreateButton, ExportButton, FilterButton, } from 'react-admin';
import { ShowButton } from 'react-admin';

const ListActions = (props) => (
  <TopToolbar>
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const MediaOwnerList = (props) => {

  return (
  <List {...props} actions={<ListActions />} bulkActionButtons={false}>
    <Datagrid>
      <TextField source="mediaowner" />
      <TextField source="name" />
      <TextField source="website" />
      <TextField source="p_iva" />
      <TextField source="pec" />
      <FunctionField label="Stripe Account" render={record => <a target="_blank" href={`https://dashboard.stripe.com/test/connect/accounts/${record?.stripe_account}/activity`}>{record?.stripe_account ?? ""}</a>} />
      <DateField source="subscription_date" label="data di sottoscrizione" />
      <ShowButton basePath="/mediaowners" label="Show" record={props.record} />
    </Datagrid>
  </List>
)};

export const MediaOwnerShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="mediaowner" fullWidth={true} />
      <TextField source="name" fullWidth={true} />
      <TextField source="website" fullWidth={true} />
      <TextField source="p_iva" fullWidth={true} />
      <TextField source="pec" fullWidth={true} />
      <FunctionField label="Stripe Account" render={record => <a target="_blank" href={`https://dashboard.stripe.com/test/connect/accounts/${record?.stripe_account}/activity`}>{`https://dashboard.stripe.com/test/connect/accounts/${record?.stripe_account}/activity`}</a>} />
      <DateField source="subscription_date" label="data di sottoscrizione" />
    </SimpleShowLayout>
  </Show>
);