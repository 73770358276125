
import React, { cloneElement, useMemo } from 'react';
import { List, Datagrid, TextField, DateField, BooleanField } from 'react-admin';

import { ReferenceArrayField, SingleFieldList, ArrayField, FunctionField, Menu, Layout, MenuItemLink, DashboardMenuItem, EmailField, NumberField, ChipField, SimpleFormIterator, ArrayInput, Create, Edit, SimpleForm, TextInput, NumberInput, Button, Show, SimpleShowLayout, RichTextField, ReferenceField, TopToolbar, CreateButton, ExportButton, FilterButton, } from 'react-admin';
import { ShowButton } from 'react-admin';

const ListActions = (props) => (
  <TopToolbar>
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const PricingList = (props) => (
  <List {...props} actions={<ListActions />} bulkActionButtons={false}>
    <Datagrid>
      <TextField source="media_owner" />
      <TextField source="customer" />
      <TextField source="rule_type" />
      <DateField source="from" label="data di inizio" />
      <DateField source="to" label="data di fine" />
      <NumberField source="amount_1" options={{ style: 'currency', currency: 'EUR' }} />
      <TextField source="condition_1" />
      <NumberField source="percentage" />
      <ShowButton basePath="/availabilities" label="Show" record={props.record} />
    </Datagrid>
  </List>
);