import { stringify } from 'query-string';
import { fetchUtils, DataProvider } from 'ra-core';
import { ApolloClient, InMemoryCache, gql, useQuery, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { Amplify } from "@aws-amplify/core";
import { Filter } from "./Filter";
import { Pagination } from "./Pagination";
import { getListFactory } from './getList';
import { getManyFactory } from './getMany';
import { getOneFactory } from './getOne';
import { createFactory } from './create';
import { deleteFactory } from './delete';

export default (
  apiUrl: string,
  models: any,
  httpClient = fetchUtils.fetchJson,
  countHeader: string = 'Content-Range'
): DataProvider => {

  const httpLink = new HttpLink({
    uri: apiUrl,
    // credentials: 'include',
    fetchOptions: {
      mode: "cors",
    },
  });

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    // const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them

    console.log('in getList with queries')
    const userData = await Amplify.Auth.currentSession();
    let accessToken = userData.getAccessToken()
    let jwt = accessToken.getJwtToken()
    const newHeaders = {
      headers: {
        ...headers,
        Authorization: jwt ? `${jwt}` : "",
      }
    }
    console.log('new headers: ', newHeaders);
    return newHeaders
  });

  console.log('with api url: ', apiUrl)
  const cache = new InMemoryCache();
  console.log('with authLink: ', authLink);
  const client = new ApolloClient({
    // Provide required constructor fields
    cache: cache,
    link: authLink.concat(httpLink),
    // Provide some optional constructor fields
    name: 'react-web-client',
    version: '1.3',
    queryDeduplication: false,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    }
  });

  // const queries = makeQueries(models);

  return {
    getList: getListFactory(client, models),
    getOne: getOneFactory(client, models),
    getMany:getManyFactory(client, models),

    getManyReference: (resource, params) => {
      console.log('in get getManyReference');

      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;

      const rangeStart = (page - 1) * perPage;
      const rangeEnd = page * perPage - 1;

      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify({
          ...params.filter,
          [params.target]: params.id,
        }),
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      const options =
        countHeader === 'Content-Range'
          ? {
            // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
            headers: new Headers({
              Range: `${resource}=${rangeStart}-${rangeEnd}`,
            }),
          }
          : {};

      return httpClient(url, options).then(({ headers, json }) => {
        if (!headers.has(countHeader)) {
          throw new Error(
            `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
          );
        }
        return {
          data: json,
          total:
            countHeader === 'Content-Range'
              ? parseInt(
                headers.get('content-range').split('/').pop(),
                10
              )
              : parseInt(headers.get(countHeader.toLowerCase())),
        };
      });
    },

    update: createFactory(client, models),

    // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    updateMany: (resource, params) =>
      Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
          })
        )
      ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

    create: createFactory(client, models),
    delete: deleteFactory(client, models),
    deleteMany: (resource, params) =>
      Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE',
            headers: new Headers({
              'Content-Type': 'text/plain',
            }),
          })
        )
      ).then(responses => ({
        data: responses.map(({ json }) => json.id),
      })),
  }
};