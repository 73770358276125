import { Amplify } from "@aws-amplify/core";
import { Filter } from "./Filter";
import { Pagination } from "./Pagination";
import { ApolloClient, InMemoryCache, gql, useQuery, HttpLink } from '@apollo/client';
import { Buffer } from "buffer"
import raw from "raw.macro";

//REFRESH 2
const pluralize = require('pluralize')
const velocity = require('velocityjs');
const vtl = raw("./vtl/getOne.vtl");
console.log('with vtl: ', vtl);


const makeQueries = (models, modelName, params, keys) => {

  console.log('in makeQueries: ', models, modelName)
  try {
    const context = {
      model: models[modelName],
      modelName: modelName,
      keys: keys,
      params: params,
      utils: {
        capitalize: (value) => value.charAt(0).toUpperCase() + value.slice(1),
        plural: (value) => pluralize.plural(value),
        expand: (value) => value.join("\n"),
        singular: (value) => pluralize.singular(value)

      }
    }

    console.log('with vtl: ', vtl.toString());
    console.log('with model: ', models[modelName]);
    const result = velocity.render(vtl.toString(), context, {});
    console.log('with velocity result: ', result);
    return gql(result)
  } catch (error) {
    console.error('with error in makeQueries: ', error);
  }

}

export const getOneFactory = (client, models) => {

  return async (resource, params) => {

    console.log('in get one factory with ', resource, params);

    //Decode id:
    const idStruct = JSON.parse(Buffer.from(params.id, "base64").toString());
    console.log('with idStruct: ', idStruct);

    const queries = makeQueries(models, resource, params, idStruct);
    const entityName = resource.charAt(0).toUpperCase() + resource.slice(1);
    let queryName = `get${pluralize.singular(entityName)}`
    console.log('with queries: ', queries);

    const variables = { [models[resource].keys.hash]: idStruct.hash }
    if (models[resource].keys.range) {
      variables[models[resource].keys.range] = idStruct.range
    }

    return new Promise(async (resolve, reject) => {

      const result = await client.query({ query: queries, variables: variables });
      console.log('with result: ', result);
      const rawData = result.data[queryName];

      const idStruct = { hash: rawData[models[resource].keys.hash] }
      if (models[resource].keys.range) {
        const index: string = models[resource].keys.range;
        idStruct["range"] = rawData[models[resource].keys.range]
      }

      const _id = Buffer.from(JSON.stringify(idStruct)).toString("base64")
      console.log('with rawData: ', rawData)

      //If model has reference
      let references = {}
      console.log('before REFERENCE')
      if (models[resource].references && Object.keys(models[resource].references).length > 0) {
        console.log('REFERENCE: in')
        for (let i = 0; i < Object.keys(models[resource].references).length; i++) {
          const reference_name = Object.keys(models[resource].references)[i];
          const reference_model = models[resource].references[reference_name].model;
          console.log('reference_name: ', reference_name)
          console.log('reference_model: ', reference_model)

          if (models[resource].references[reference_name].multi != undefined) {
            console.log('in multi for field : ', models[resource].references[reference_name].multi)
            const multi_key = models[resource].references[reference_name].multi;
            const multi_field = models[resource].references[reference_name].reference_keys[multi_key];
            const results = []
            for (let i = 0; i < rawData[multi_field].length; i++) {
              console.log('with data: ', rawData[multi_field][i])
              const referenceStruct = { hash: multi_key == "hash" ? rawData[multi_field][i] : rawData[models[resource].references[reference_name].reference_keys.hash] }
              if (models[reference_model].keys.range) {
                referenceStruct["range"] = multi_key == "range" ? rawData[multi_field][i] : rawData[models[resource].references[reference_name].reference_keys.range]
              }
              console.log('getOne reference: ', referenceStruct);
              results.push(Buffer.from(JSON.stringify(referenceStruct)).toString("base64"))
            }
            references[reference_name] = results;
          } else {

            const referenceStruct = { hash: rawData[models[resource].references[reference_name].reference_keys.hash] }
            if (models[reference_model].keys.range) {
              referenceStruct["range"] = rawData[models[resource].references[reference_name].reference_keys.range]
            }
            console.log('referenceStruct: ', referenceStruct)

            references[reference_name] = Buffer.from(JSON.stringify(referenceStruct)).toString("base64")

          }
        }
      }

      if (rawData.id) {
        console.log('with id: old', rawData.id)
        const { id, ...new_item } = rawData;
        console.log('with id and new item: ', id, new_item);
        const data = { id: _id, ...rawData, ...references }
        console.log('final data: ', data)
        resolve({ data })
      } else {
        const data = { id: _id, ...rawData, ...references }
        console.log('final data: ', data)
        resolve({ data })
      }


    }) as any;
  }
}